import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  CameraPosition,
  Path,
  Tileset,
  ViewService,
  ViewSettings,
} from '@seurat/testbed';
import { TrimbleRotation, Vector3, Web3DViewer } from '@technology/web3d';
import {
  PotreeManagerModel,
  PotreeManagerPlugin,
} from '@technology/web3d-plugin-potreemanager';
import { concatMap, delay, from, of } from 'rxjs';
import { loadWeb3d } from './web3d-loader';

// Values below confirmed with John McCauly from Technology Group
const DEFAULT_SETTINGS = {
  pointBudget: 1.5 * 1000 * 1000,
  pointDensity: 1.5,
};

@Component({
  selector: 'tb-viewer',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  template: `<div #viewerHost class="h-full"></div>`,
})
export class ViewerComponent implements AfterViewInit {
  private settings: ViewSettings | null = null;
  private viewer!: Web3DViewer;
  private model?: PotreeManagerModel;

  @ViewChild('viewerHost', { static: false }) private viewerHost!: ElementRef;

  constructor(private viewService: ViewService, private renderer: Renderer2) {}

  async ngAfterViewInit() {
    await this.init();

    this.viewService.positionProvider = () => {
      const camera = this.viewer.camera;
      return {
        position: camera.position.clone(),
        rotation: { ...camera.rotation },
      };
    };

    this.viewService.setDefaultSettings(DEFAULT_SETTINGS);

    this.viewService.tileset$.subscribe(async (tileset) => {
      await this.setTileset(tileset);
    });

    this.viewService.flyTo$.subscribe((position) => {
      this.flyTo(position);
    });

    this.viewService.play$.subscribe((path) => {
      this.play(path);
    });

    this.viewService.settings$.subscribe(async (settings) => {
      this.settings = settings;
    });
  }

  private async init() {
    await loadWeb3d();

    this.viewer = new Web3DViewer({
      staticRootUrl: 'web3d/',
    });

    const plugin = new PotreeManagerPlugin();
    this.viewer.addPlugin(plugin);

    plugin.eyeDomeLightingEnabled = true;
    plugin.eyeDomeLightingRadius = 1.4;
    plugin.eyeDomeLightingStrength = 0.7;

    this.renderer.appendChild(this.viewerHost.nativeElement, this.viewer);
  }

  private async setTileset(tileset: Tileset | null) {
    if (this.model) {
      await this.viewer.unload(this.model.modelId);
      this.model = undefined;
    }

    if (!tileset) return;

    const plugin = this.viewer.plugins.get(PotreeManagerPlugin);

    this.model = await plugin.load(tileset.url, { modelId: tileset.id });
    if (this.settings !== null) {
      this.model.pointBudget = this.settings.pointBudget;
      this.model.pointDensityBias = this.settings.pointDensity;
    }
    await this.viewer.camera.fitToView();
  }

  flyTo(position: CameraPosition) {
    this.viewer.camera.position = position.position as Vector3;
    this.viewer.camera.rotation = position.rotation as TrimbleRotation;
  }

  private play(path: Path) {
    if (path.positions.length === 0) return;

    const pos1 = path.positions[0];

    // Jump to first position
    this.viewer.camera.position = pos1.position as Vector3;
    this.viewer.camera.rotation = pos1.rotation as TrimbleRotation;

    // Ease to each of the other positions
    from(path.positions.slice(1))
      .pipe(concatMap((vals) => of(vals).pipe(delay(path.delay))))
      .subscribe((position) => {
        this.viewer.camera.animate(
          position.position as Vector3,
          undefined,
          position.rotation,
          0.5 * path.delay
        );
      });
  }
}
