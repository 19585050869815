import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SidePanelComponent } from '@seurat/testbed';
import { ViewerComponent } from './viewer.component';
import { environment } from './environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './auth.config';

@Component({
  selector: 'tb-root',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SidePanelComponent, ViewerComponent],
  template: `
    <div class="h-full flex overflow-hidden">
      <tb-side-panel
        [title]="title"
        class="border-r border-gray-1 w-96"
      ></tb-side-panel>
      <tb-viewer class="flex-grow"></tb-viewer>
    </div>
  `,
})
export class AppComponent {
  title = environment.title;

  constructor(oauthService: OAuthService) {
    oauthService.configure(authCodeFlowConfig);
  }
}
